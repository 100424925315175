<template>
  <div>
    <div class="Zhanpingbanner">
      <img class="Zhanpingbannerpc" :src="img" alt="" />
      <img class="Zhanpingbannerios" :src="imgios" alt="" />
    </div>
    <div class="Zhanping">
      <el-button
        @click="dialogVisiblesq = true"
        plain
        class="Zhanping_fabubtn Zhanping_sqbtn"
        >我要申请毕设展</el-button
      >
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        v-loading="loading"
      >
        <el-tab-pane
          v-for="(tab, index) in tabsall"
          :key="index"
          :label="tab.label"
          :name="tab.name"
        >
          <div class="Zhanping_shai" v-if="false">
            <div>
              <el-select v-model="optionsvalue_hd" placeholder="活动形式">
                <el-option
                  v-for="(item, index) in options_hdxs"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="moneyval_hd" placeholder="付费类型">
                <el-option
                  v-for="(item, index) in money_hd"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="paixuval_hd" placeholder="排序">
                <el-option
                  v-for="(item, index) in paixu_hd"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="hdtypeval_hd" placeholder="活动状态">
                <el-option
                  v-for="(item, index) in hdtype_hd"
                  :key="index"
                  :label="item.t"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <ul
            v-if="tabsallultype"
            class="zhanping_lb_allul"
            ref="zhanping_lb_allul"
          >
            <li
              class="zhanping_lb_allulli"
              v-for="item in zhanping_lb_allulli"
              :key="item.title"
              @click="bishezhan(item.d_id, item.drawtext)"
              v-show="item.d_type == tab.d_type || tab.d_type === true"
              :class="item.d_id ? '' : 'zhanping_lb_allullizero'"
            >
              <div class="zhanping_lb_allullitimg">
                <div class="zhanping_lb_allullitimgtext">
                  {{ item.d_type == 1 ? "毕设展" : "作品展览" }}
                </div>
                <img v-lazy="item.draw_pic" alt="" />
              </div>
              <div class="zhanping_lb_allullictext">
                <div class="zhanping_lb_allullictexttop">
                  <div class="zhanping_lb_allullictext_title">
                    {{ item.title }}
                  </div>
                  <div class="zhanping_lb_allullictext_sj">
                    {{ item.draw_start }} ~ {{ item.draw_end }}
                  </div>
                  <div class="shejihdulli_timetype">
                    <div
                      v-if="item.draw_zt == 1"
                      class="shejihdulli_timetype_1"
                    ></div>
                    <div
                      v-if="item.draw_zt != 1"
                      class="shejihdulli_timetype_2"
                    ></div>
                    <span>{{ item.draw_zt == 1 ? "进行中" : "已结束" }}</span>
                  </div>
                </div>
              </div>
            </li>
            <li class="lizero" v-if="lizero">
              <img
                src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
                alt=""
              />
            </li>
          </ul>
          <ul
            class="shejihdul"
            v-if="tabsallultype_hd"
            style="margin-top: 2rem"
          >
            <li
              class="shejihdulli"
              v-for="(item, index) in shejihdulall"
              :key="index"
              @click="shejihdulliclick(item.a_id)"
            >
              <div class="shejihdulli_img">
                <img
                  class="shejihdulli_imgimg"
                  v-lazy="item.title_pic"
                  alt=""
                />
                <div
                  class="shejihdulli_money"
                  v-if="item.pay_state == 0 ? false : true"
                >
                  <div class="shejihdulli_moneytype">收费</div>
                </div>
              </div>
              <div class="shejihdulli_title">
                <div>{{ item.a_type == 1 ? "线上活动" : "线下活动" }}</div>
                <p>{{ item.title }}</p>
              </div>
              <div class="shejihdulli_time">
                <p class="shejihdulli_timep">
                  {{ item.draw_start }} ~ {{ item.draw_end }}
                </p>

                <div
                  class="shejihdulli_timetype"
                  style="right: -1.3rem; top: 1rem"
                >
                  <div
                    v-if="item.draw_zt == 1"
                    class="shejihdulli_timetype_1"
                  ></div>
                  <div
                    v-if="item.draw_zt != 1"
                    class="shejihdulli_timetype_2"
                  ></div>
                  <span>{{ item.draw_zt == 1 ? "进行中" : "已结束" }}</span>
                </div>
              </div>
            </li>
            <li class="lizero" v-if="lizero">
              <img
                src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
                alt=""
              />
            </li>
          </ul>
          <div v-if="!paginationtype" class="zhansaizerobox">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        :pager-count="pagercount"
        @current-change="currentchange"
        :page-size="pagesize"
        :current-page.sync="currentPage1"
        background
        layout="prev, pager, next"
        :total="total"
        v-if="paginationtype"
      >
      </el-pagination>
      <el-dialog
        title="2021毕设展申请"
        center
        :visible.sync="dialogVisiblesq"
        width="30%"
        v-loading="sqloading"
        element-loading-text="申请提交中！"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="bsz_sq_tbox">
          <p>
            咨询2021毕设展申请与商务合作，请填写以下表单，我们将在第一时间回复您
          </p>
          <el-input v-model="sqschool" placeholder="请输入院校名"></el-input>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请简单填写需求"
            v-model="sqxuqiu"
          >
          </el-input>
          <el-input v-model="squser" placeholder="请输入联系人"></el-input>
          <el-input
            v-model="sqphone"
            placeholder="请输入联系人手机号码"
          ></el-input>
          <el-input
            v-model="sqemail"
            placeholder="请输入电子邮箱（选填）"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblesq = false">取 消</el-button>
          <el-button type="primary" @click="bs_shenqing">立即申请</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sqloading: false,
      sqschool: "",
      sqxuqiu: "",
      squser: "",
      sqphone: "",
      sqemail: "",
      dialogVisiblesq: false,
      loading: true,
      tabindexzp: "1",
      pagercount: 5,
      tabsallultype: true,
      tabsallultype_hd: false,
      paginationtype: true,
      currentPage1: 1,
      pagesize: 12,
      img: "https://img.hozoin.cn/news_img/20221128/16696357470001568.jpg",
      imgios:
        "https://img.hozoin.cn/news_img/20221128/16696357470001568.jpg",
      imgall: [
        "https://img.hozoin.cn/news_img/20221128/16696357470001568.jpg",
        "https://task.hozoin.cn/Works/20210222/sj-6860027219.png",
        "https://task.hozoin.cn/Works/20210223/sj-9667287417.png",
        "https://task.hozoin.cn/Works/20210204/sj-3543001249.png",
      ],
      imgiosall: [
        "https://img.hozoin.cn/news_img/20221128/16696357470001568.jpg",
        "https://task.hozoin.cn/Works/20210310/sj-7517645836.png",
        "https://task.hozoin.cn/Works/20210310/sj-7610124636.png",
        "https://task.hozoin.cn/Works/20210310/sj-5096581314.png",
      ],
      total: 0,
      lizero: false,
      activeName: "first",
      options: [
        {
          value: "选项1",
        },
        {
          value: "选项2",
        },
        {
          value: "选项3",
        },
        {
          value: "选项4",
        },
        {
          value: "选项5",
        },
      ],
      optionsvalue: "",
      zhanping_lb_allulli: [],
      userinfo: {},
      tabsall: [
        {
          label: "全部",
          name: "first",
          d_type: true,
        },
        {
          label: "毕设展",
          name: "two",
          d_type: "1",
        },
        {
          label: "作品展览",
          name: "three",
          d_type: "2",
        },
        {
          label: "参赛活动",
          name: "four",
          d_type: "3",
        },
      ],
      tabshai: true,
      tabindex: "0",
      shejihdulall: [],
      activeName_hd: "",
      // activeName_hdall: [
      //   {
      //     label: "全部",
      //     name: "0",
      //   },
      //   {
      //     label: "沙龙",
      //     name: "1",
      //   },
      //   {
      //     label: "竞赛",
      //     name: "2",
      //   },
      //   {
      //     label: "展评",
      //     name: "3",
      //   },
      //   {
      //     label: "专题",
      //     name: "4",
      //   },
      // ],
      optionsvalue_hd: "",
      options_hdxs: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "线上",
        },
        {
          value: "2",
          t: "线下",
        },
      ],
      paixuval_hd: "",
      paixu_hd: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "2",
          t: "热度",
        },
        {
          value: "1",
          t: "时间",
        },
      ],
      moneyval_hd: "",
      money_hd: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "免费",
        },
        {
          value: "2",
          t: "收费",
        },
      ],
      hdtypeval_hd: "",
      hdtype_hd: [
        {
          value: "0",
          t: "全部",
        },
        {
          value: "1",
          t: "进行中",
        },
        {
          value: "2",
          t: "已结束",
        },
      ],
    };
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.axiosqq(1);
    this.fenxiang(
      "和作营-线上展览",
      "随时随地掌上看展，足不出户看遍世界顶级创意。",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
  },
  methods: {
    handleClick(tab, event) {
      this.tabindexzp = tab.index;
      var indexid = tab.index;
      this.img = this.imgall[indexid];
      this.imgios = this.imgiosall[indexid];
      if (tab.index == "3") {
        this.tabsallultype = false;
        this.tabsallultype_hd = true;
        this.sx(1);
      } else {
        this.tabsallultype = true;
        this.tabsallultype_hd = false;
        this.tabindex = tab.index;
        this.currentPage1 = 1;
        this.axiosqq(1);
      }
    },
    axiosqq(page) {
      var that = this;
      that.loading = true;
      this.$axios({
        url: "/api/getDrawList",
        method: "post",
        data: {
          d_type: this.tabindex,
          page: page,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.loading = false;
          that.paginationtype = true;
          that.zhanping_lb_allulli = res.data.result.list;
          var zerobq = res.data.result.list.length % 4;
          that.total = res.data.result.count;
          var lizero = "";
          if (zerobq == 2) {
            that.zhanping_lb_allulli.push(lizero);
            that.zhanping_lb_allulli.push(lizero);
          } else if (zerobq == 3) {
            that.zhanping_lb_allulli.push(lizero);
          }
        } else {
          that.paginationtype = false;
          that.loading = false;
        }
      });
    },
    bishezhan(id, url) {
      //url 学院简介
      const ids = [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63];
      if (ids.indexOf(id) !== -1) {
        window.open(url);
      } else {
        this.$router.push({ path: "/Zhanping_bishe", query: { data: id } });
      }
    },
    currentchange(index) {
      document.documentElement.scrollTop = 0;
      if (this.tabindexzp == "3") {
        this.sx(index);
      } else {
        this.axiosqq(index);
      }
    },
    sx(page) {
      var that = this;
      that.shejihdulall = [];
      this.$axios({
        url: "/api/getActivityList",
        method: "post",
        data: {
          a_type: that.activeName_hd,
          a_shape: that.optionsvalue_hd,
          is_sorting: that.paixuval_hd,
          is_money: that.moneyval_hd,
          is_ongoing: that.hdtypeval_hd,
          page,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.paginationtype = true;
          that.lizero = false;
          that.shejihdulall = res.data.result.list;
          that.total = res.data.result.count;
        } else {
          that.lizero = true;
          that.paginationtype = false;
        }
      });
    },
    shejihdulliclick(id) {
      this.$router.push({ path: "/shejihd_c", query: { data: id } });
    },
    bs_shenqing() {
      var that = this;
      that.sqloading = true;
      this.$axios({
        url: "/api/userMessage",
        method: "post",
        data: {
          school: that.sqschool,
          username: that.squser,
          email: that.sqemail,
          mobile: that.sqphone,
          content: that.sqxuqiu,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.dialogVisiblesq = false;
          that.sqloading = false;
          this.$message({
            message: "申请已提交！",
            type: "success",
          });
        } else {
          that.dialogVisiblesq = false;
          that.sqloading = false;
          that.$message(res.data.messages);
        }
      });
    },
  },
};
</script>
<style>
@import "../assets/css/zhanping.css";
@import "../assets/css/shejihd.css";
</style>
